exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-glossary-js": () => import("./../../../src/pages/en/glossary.js" /* webpackChunkName: "component---src-pages-en-glossary-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nd-glossary-js": () => import("./../../../src/pages/nd/glossary.js" /* webpackChunkName: "component---src-pages-nd-glossary-js" */),
  "component---src-pages-nd-index-js": () => import("./../../../src/pages/nd/index.js" /* webpackChunkName: "component---src-pages-nd-index-js" */),
  "component---src-pages-nd-search-js": () => import("./../../../src/pages/nd/search.js" /* webpackChunkName: "component---src-pages-nd-search-js" */),
  "component---src-pages-sn-glossary-js": () => import("./../../../src/pages/sn/glossary.js" /* webpackChunkName: "component---src-pages-sn-glossary-js" */),
  "component---src-pages-sn-index-js": () => import("./../../../src/pages/sn/index.js" /* webpackChunkName: "component---src-pages-sn-index-js" */),
  "component---src-pages-sn-search-js": () => import("./../../../src/pages/sn/search.js" /* webpackChunkName: "component---src-pages-sn-search-js" */),
  "component---src-templates-myth-en-js": () => import("./../../../src/templates/myth-en.js" /* webpackChunkName: "component---src-templates-myth-en-js" */),
  "component---src-templates-myth-nd-js": () => import("./../../../src/templates/myth-nd.js" /* webpackChunkName: "component---src-templates-myth-nd-js" */),
  "component---src-templates-myth-sn-js": () => import("./../../../src/templates/myth-sn.js" /* webpackChunkName: "component---src-templates-myth-sn-js" */),
  "component---src-templates-questions-en-js": () => import("./../../../src/templates/questions-en.js" /* webpackChunkName: "component---src-templates-questions-en-js" */),
  "component---src-templates-questions-nd-js": () => import("./../../../src/templates/questions-nd.js" /* webpackChunkName: "component---src-templates-questions-nd-js" */),
  "component---src-templates-questions-sn-js": () => import("./../../../src/templates/questions-sn.js" /* webpackChunkName: "component---src-templates-questions-sn-js" */),
  "component---src-templates-quiz-en-js": () => import("./../../../src/templates/quiz-en.js" /* webpackChunkName: "component---src-templates-quiz-en-js" */),
  "component---src-templates-quiz-nd-js": () => import("./../../../src/templates/quiz-nd.js" /* webpackChunkName: "component---src-templates-quiz-nd-js" */),
  "component---src-templates-quiz-sn-js": () => import("./../../../src/templates/quiz-sn.js" /* webpackChunkName: "component---src-templates-quiz-sn-js" */),
  "component---src-templates-slideshow-en-js": () => import("./../../../src/templates/slideshow-en.js" /* webpackChunkName: "component---src-templates-slideshow-en-js" */),
  "component---src-templates-slideshow-nd-js": () => import("./../../../src/templates/slideshow-nd.js" /* webpackChunkName: "component---src-templates-slideshow-nd-js" */),
  "component---src-templates-slideshow-sn-js": () => import("./../../../src/templates/slideshow-sn.js" /* webpackChunkName: "component---src-templates-slideshow-sn-js" */),
  "component---src-templates-unit-en-js": () => import("./../../../src/templates/unit-en.js" /* webpackChunkName: "component---src-templates-unit-en-js" */),
  "component---src-templates-unit-nd-js": () => import("./../../../src/templates/unit-nd.js" /* webpackChunkName: "component---src-templates-unit-nd-js" */),
  "component---src-templates-unit-sn-js": () => import("./../../../src/templates/unit-sn.js" /* webpackChunkName: "component---src-templates-unit-sn-js" */)
}

